const products = [
  {
    id: 1,
    name: "Falda Midi",
    description:
      "Se hace en fibrana previamente lavada, muchas estampas y colores lisos disponibles! Con cintura elastizada.",
    price: 30900,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631534186_60dfbbb492_b.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 2,
    name: "Top microtul", 
    description: "Top hecho en microtul, muchos colores disponibles!",
    price: 25600,
    description2: "También se hace en mangas corta.",
    price2: 24400,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53631970985_1c9234494b_b.jpg",
      "https://live.staticflickr.com/65535/53631863909_a679413e1a_c.jpg",
      "https://live.staticflickr.com/65535/53631863884_89c01182db_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 3,
    name: "Falda Lea",
    description:
      "Se hace en gabardina, varios colores y estampas cuadrilles disponibles!",
    price: 31200,
    description2: "*Estampa de la foto no disponible!",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631871154_569f81a656_c.jpg",
      "https://live.staticflickr.com/65535/53631742913_bf2e8a6b41_c.jpg",
      "https://live.staticflickr.com/65535/53631742903_ba7c317177_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 4,
    name: "Falda Media Campana",
    description:
      "Se hace en fibrana previamente lavada, muchas estampas y colores lisos disponibles! Con cintura elastizada.",
    price: 26700,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631978540_0958d6bea4_c.jpg",
      "https://live.staticflickr.com/65535/53631871104_b068efd04c_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 5,
    name: "Camisa Unisex",
    description:
      "La textura de la tela es MUY linda y cómoda, y los colores son hermosos. Talle aprox M/L pueden pedir las medidas ❤️",
    price: 29300,
    description2: "Camisa tipo crop precio alternativo",
    price2: 27200,
    category: ["camisas"],
    image: [
      "https://live.staticflickr.com/65535/53630627507_9c530fc4df_c.jpg",
      "https://live.staticflickr.com/65535/53631966220_082af18a6a_c.jpg",
      "https://live.staticflickr.com/65535/53631730763_43275d6788_c.jpg",
      "https://live.staticflickr.com/65535/53630627432_53926eb93b_c.jpg",
      "https://live.staticflickr.com/65535/53631859559_c1a23aff28_c.jpg",
      "https://live.staticflickr.com/65535/53631966195_1381e7d129_c.jpg",
      "https://live.staticflickr.com/65535/53631730783_c6e76a8c73_c.jpg",
      "https://live.staticflickr.com/65535/53630627437_9be0194882_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 6,
    name: "Falda Cuadrille",
    description: "Tela de gabardina elastizada! (Sin cierre)",
    price: 26700,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631976095_85c5a76b8e_c.jpg",
      "https://live.staticflickr.com/65535/53631531951_5b81a64bd4_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 7,
    name: "Falda Cutie",
    description: "Falda semitableada por el frente en corte A.",
    price: 27600,
    description2: "Se hace en gabardina. Varios colores disponibles!",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631534301_8039c9a35c_c.jpg",
      "https://live.staticflickr.com/65535/53631742978_4fa21c023d_c.jpg",
      "https://live.staticflickr.com/65535/53630639227_920d7619b3_c.jpg",
      "https://live.staticflickr.com/65535/53631534261_389cf54d41_c.jpg",
      "https://live.staticflickr.com/65535/53631978580_0e220ea6d0_c.jpg",
    ],
    product_qty: 1,
  },
/*  
{
    id: 8,
    name: "Vestido Panda",
    description:
      "Vestido hecho en gabardina, el diseño lleva pinzas y hebillas metálicas, con un cinturón frontal que se ubica en el bajo busto.",
    price: 36700,
    description2: "Ideal para usar solo o con otras prendas por debajo!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631977440_821125168b_c.jpg",
      "https://live.staticflickr.com/65535/53631977445_b34a53ac8c_c.jpg",
    ],
    product_qty: 1,
  },
  */
  {
    id: 9,
    name: "Falda Volados",
    description:
      "Se hace en fibrana previamente lavada, muchas estampas y colores lisos disponibles! Con cintura elastizada.",
    price: 27000,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631978490_6b7f797bcc_c.jpg",
      "https://live.staticflickr.com/65535/53631534156_f1064b53db_c.jpg",
      "https://live.staticflickr.com/65535/53631871054_de67bffb35_h.jpg",
      "https://live.staticflickr.com/65535/53631742783_2499b2fb9a_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 10,
    name: "Falda Tableada",
    description:
      "Falda tableada, puede hacerse en gabardina en varios colores!",
    price: 42500,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53630639157_9dd40ecf86_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 11,
    name: "Falda Cindy",
    description:
      "La falda Cindy es una falda de corte clásico, pensada para que puedas diseñarla como te guste, estampada, lisa, en gabardina o en jean!",
    price: 27100,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53630636912_cf7a3184f8_c.jpg",
      "https://live.staticflickr.com/65535/53631740713_c0730d6d8b_c.jpg",
      "https://live.staticflickr.com/65535/53631740698_d52eb9345b_c.jpg",
      "https://live.staticflickr.com/65535/53631740678_b95156b828_c.jpg",
      "https://live.staticflickr.com/65535/53631740653_7e095729b8_c.jpg",
      "https://live.staticflickr.com/65535/53631531981_54660c8d0d_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 12,
    name: "Falda Cindy Lana",
    description:
      "Esta falda es igual a la falda Cindy pero se hace en lana sintética",
    price: 27100,
    description2: "",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53630636852_e817700814_c.jpg",
      "https://live.staticflickr.com/65535/53630636837_a98cd30769_c.jpg",
    ],
    product_qty: 1,
  },
  // {
  //   id: 13,
  //   name: "Remeras",
  //   description:
  //     "Remeras estampadas con diseños muy lindos! Medidas: Ancho: 63cm, Largo: 74cm",
  //   price: 14200,
  //   description2: "*Disponibles estampa de Gato, Drama Queen y Alien.",
  //   price2: 0,
  //   category: ["tops"],
  //   image: [
  //     "https://live.staticflickr.com/65535/53631750783_2cd47daebc_c.jpg",
  //     "https://live.staticflickr.com/65535/53630647222_9e81a0d553_c.jpg",
  //     "https://live.staticflickr.com/65535/53631879114_a847ffc8e9_c.jpg",
  //     "https://live.staticflickr.com/65535/53630647232_f6286c89bd_c.jpg",
  //     "https://live.staticflickr.com/65535/53631542066_2e14f1cf89_c.jpg",
  //     "https://live.staticflickr.com/65535/53631879139_6a52397a9f_c.jpg",
  //     "https://live.staticflickr.com/65535/53631879109_cc2e2464f1_c.jpg",
  //   ],
  //   product_qty: 1,
  // },
  {
    id: 14,
    name: "Blusa Peter",
    description:
      "Top hecho en fibrana previamente lavada, con cuello de batista y entretela para generar rigidez.",
    price: 27800,
    description2: "Se puede hacer en varios colores!",
    price2: 0,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53631970910_508dd5d3d0_c.jpg",
      "https://live.staticflickr.com/65535/53630631797_f744c0b049_c.jpg",
      "https://live.staticflickr.com/65535/53631970925_96cebed382_c.jpg",
      "https://live.staticflickr.com/65535/53630631787_f37817bf44_c.jpg",
      "https://live.staticflickr.com/65535/53630631807_006f9b918e_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 15,
    name: "Vestido Peter",
    description: "Vestido hecho en lino, lleva bolsillos grandes por delante!",
    price: 47200,
    description2:
      "Es muy cómodo y lindo a la vista, puede hacerse en varios colores!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631870089_b618023176_c.jpg",
      "https://live.staticflickr.com/65535/53631870059_5a3a15e068_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 16,
    name: "Vestido Bengalina",
    description: "Vestido de bengalina al cuerpo, muy elastizado.",
    price: 28500,
    description2: "Puede hacerse en varios colores!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631862829_e7893fec72_c.jpg",
      "https://live.staticflickr.com/65535/53631969820_b6de73ea4b_c.jpg",
      "https://live.staticflickr.com/65535/53631734218_10f1fe09a3_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 17,
    name: "Top microtul ángeles",
    description:
      "Top hecho en microtul estampado, puede hacerse en manga larga y en manga corta.",
    price: 30800,
    description2: "Top microtul ángeles descripción 2",
    price2: 28800,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53631735438_9a65a4c647_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 18,
    name: "Top microtul astral",
    description: "Descripción Top microtul astral",
    price: 30800,
    description2: "Top microtul astral descripción 2",
    price2: 28800,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53631863939_fdfcba10f6_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 19,
    name: "Top microtul dragones",
    description: "Descripción Top microtul dragones",
    price: 30800,
    description2: "Top microtul dragones descripción 2",
    price2: 28800,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53631863929_2023cac5fc_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 20,
    name: "Top Panda",
    description:
      "Top hecho en gabardina, el diseño lleva pinzas y hebillas metálicas.",
    price: 25900,
    description2: "",
    price2: 0,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53631735378_b4c9dc4588_c.jpg",
      "https://live.staticflickr.com/65535/53631526936_639654e401_c.jpg",
      "https://live.staticflickr.com/65535/53631863859_83bd19863d_c.jpg",
      "hhttps://live.staticflickr.com/65535/53631863849_8e7287f9f3_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 21,
    name: "Jardinero Over Marrón",
    description:
      "Jardinero Unisex, puede hacerse en gabardinas de varios colores o en jean!",
    price: 57400,
    description2:
      "Tiene 5 bolsillos funcionales, hebillas en los tirantes y viene con el cinturón al tono.",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/53631755718_e9aa72ea40_c.jpg",
      "https://live.staticflickr.com/65535/53630652037_1942ecc36c_c.jpg",
      "https://live.staticflickr.com/65535/53630652017_e116c16e3b_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 22,
    name: "Jardinero Over Negro",
    description:
      "Jardinero Unisex, puede hacerse en gabardinas de varios colores o en jean!",
    price: 57400,
    description2:
      "Tiene 5 bolsillos funcionales, hebillas en los tirantes y viene con el cinturón al tono.",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/53631883999_6d1cd6c79a_c.jpg",
      "https://live.staticflickr.com/65535/53631991415_94fdd05213_c.jpg",
      "https://live.staticflickr.com/65535/53630651997_4b151ba96b_c.jpg",
      "https://live.staticflickr.com/65535/53631755698_10a99125af_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 23,
    name: "Jardinero Over Jean",
    description:
      "Jardinero Unisex, puede hacerse en gabardinas de varios colores o en jean!",
    price: 57400,
    description2:
      "Tiene 5 bolsillos funcionales, hebillas en los tirantes y viene con el cinturón al tono.",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/53631973900_0bd1c53112_c.jpg",
      "https://live.staticflickr.com/65535/53631973930_8488225138_c.jpg",
      "https://live.staticflickr.com/65535/53630634557_26dff58f7f_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 24,
    name: "Vestido San Valentín",
    description:
      "Hecho en fibrana, tiene una caída preciosa con mucho peso por la cantidad de tela en las capas. Realmente no se llega a apreciar la calidad en fotos 🌿 (Al ser elastizado abarca muchos talles, elastiza un montón)",
    price: 44200,
    description2: "Manga larga precio alternativo",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631741823_edde650ec8_c.jpg",
      "https://live.staticflickr.com/65535/53631533116_bc5990bc53_c.jpg",
      "https://live.staticflickr.com/65535/53631977365_b2503cb265_c.jpg",
      "https://live.staticflickr.com/65535/53631741793_d878fc6f6b_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 25,
    name: "Vestido San Valentín manga larga",
    description:
      "Hecho en fibrana, tiene una caída preciosa con mucho peso por la cantidad de tela en las capas. Realmente no se llega a apreciar la calidad en fotos 🌿 (Al ser elastizado abarca muchos talles, elastiza un montón)",
    price: 46300,
    description2: "Manga larga precio alternativo",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53960877101_70085f5fc6_c.jpg",
      "https://live.staticflickr.com/65535/53961331475_62d40d95f2_c.jpg",
      "https://live.staticflickr.com/65535/53960877091_7bbbc16da6_c.jpg",
      "https://live.staticflickr.com/65535/53631741763_ff628f5d12_c.jpg",
      "https://live.staticflickr.com/65535/53631533071_d9fb4c778c_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 26,
    name: "Vestido Lexie",
    description: "Vestido hecho en corderoy!",
    price: 48300,
    description2:
      "Tiene cuello camisero y lleva un cierre funcional por todo el frente.",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631734183_6952a255e1_c.jpg",
      "https://live.staticflickr.com/65535/53631525656_03647c7952_c.jpg",
      "https://live.staticflickr.com/65535/53631525676_5f7a06aa40_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 27,
    name: "Sweater Nubes",
    description: "El sweater Nube está hecho en piel de cordero bifaz.",
    price: 52400,
    description2: "Súper suavecito, cómodo, calentito y hermoso!",
    price2: 0,
    category: ["abrigos"],
    image: [
      "https://live.staticflickr.com/65535/53631732233_42540929f2_c.jpg",
      "https://live.staticflickr.com/65535/53631860924_cba9d062a9_c.jpg",
      "https://live.staticflickr.com/65535/53631967685_aeebae7917_c.jpg",
    ],
    product_qty: 1,
  },
   /*  
  {
    id: 28,
    name: "Vestido Margot",
    description:
      "Vestido hecho en corderoy de terciopelo elastizado, de una calidad única.",
    price: 21900,
    description2: "Puede hacerse en otros colores!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631862729_e62f298ca0_c.jpg",
      "https://live.staticflickr.com/65535/53630630722_12ea94d7b3_c.jpg",
      "https://live.staticflickr.com/65535/53631734148_e622434824_c.jpg",
      "https://live.staticflickr.com/65535/53631734163_fdafbd2de1_c.jpg",
    ],
    product_qty: 1,
  }, 
*/ 
  {
    id: 29,
    name: "Vestido Atenea Estampado",
    description:
      "Vestido de diseño súper romántico! Mangas tipo farol. Largo midi, lleva cierre por la espalda y una abertura por el frente de la falda.",
    price: 45900,
    description2: "Puede hacerse en varios diseños estampados y colores lisos!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53959986682_a5c6448061_c.jpg",
      "https://live.staticflickr.com/65535/53961200199_2fa107aac4_c.jpg",
      "https://live.staticflickr.com/65535/53961124523_e7c3777389_c.jpg",
    ],
    product_qty: 1,
  },
  // {
  //   id: 30,
  //   name: "Vestido Atenea Flores",
  //   description:
  //     "Vestido de diseño súper romántico! Mangas tipo farol. Largo midi, lleva cierre por la espalda y una abertura por el frente de la falda.",
  //   price: 28900,
  //   description2: "Puede hacerse en varios diseños estampados y colores lisos!",
  //   price2: 0,
  //   category: ["vestidos"],
  //   image: [
  //     "https://live.staticflickr.com/65535/53630635617_1e8fd17aba_c.jpg",
  //     "https://live.staticflickr.com/65535/53631867674_bfe7d8ac9f_c.jpg",
  //   ],
  //   product_qty: 0,
  // },
  {
    id: 31,
    name: "Vestido Atenea Liso",
    description:
      "Vestido de diseño súper romántico! Mangas tipo farol. Largo midi, lleva cierre por la espalda y una abertura por el frente de la falda.",
    price: 45900,
    description2: "Puede hacerse en varios diseños estampados y colores lisos!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631530741_5baed32f8b_c.jpg",
      "https://live.staticflickr.com/65535/53631739398_da1bc95da9_c.jpg",
      "https://live.staticflickr.com/65535/53630635607_2ac30b3b2c_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 32,
    name: "Vestido Atenea sin mangas",
    description:
      "Vestido de diseño súper romántico! Las tiras de este vestido se ajustan en el moño del escote. Largo midi, lleva cierre por la espalda y una abertura por el frente de la falda.",
    price: 43800,
    description2: "Puede hacerse en varios diseños estampados y colores lisos!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631739388_57666d200e_c.jpg",
      "https://live.staticflickr.com/65535/53630635587_24ebd2700d_c.jpg",
      "https://live.staticflickr.com/65535/53631867584_1faea3943f_c.jpg",
      "https://live.staticflickr.com/65535/53631739333_6be0eba541_c.jpg",
      "https://live.staticflickr.com/65535/53631530671_eeaa965aa4_c.jpg",
      "https://live.staticflickr.com/65535/53631974890_d0fd126fcd_c.jpg",
    ],
    product_qty: 0,
  },
  {
    id: 33,
    name: "Vestido Sabrina",
    description:
      "Vestido confeccionado en gabardina, con recortes por el frente y por la espalda. Hermoso para usarse con remeras y topcitos abajo!.",
    price: 42500,
    description2: "Colores disponibles: Negro y marrón.",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53631754838_8386d5732a_c.jpg",
      "https://live.staticflickr.com/65535/53631990610_35b13742a3_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 34,
    name: "Top Cassie",
    description:
      "Top con mangas farol, la cintura por el frente lleva entretela para darle mayor rigidez y cuerpo a la prenda!",
    price: 25900,
    description2:
      "Los tops de la foto son hechos en batista, pero tambien puede hacerse en fibrana. Varios colores y estampas!",
    price2: 0,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53630649897_a777a1c308_c.jpg",
      "https://live.staticflickr.com/65535/53631882034_57b53757f0_c.jpg",
      "https://live.staticflickr.com/65535/53631882019_8a122e0472_c.jpg",
      "https://live.staticflickr.com/65535/53630649907_cae5b3a609_c.jpg",
      "https://live.staticflickr.com/65535/53631989475_0641d42dec_c.jpg",
      "https://live.staticflickr.com/65535/53631544676_a6af57c688_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 35,
    name: "Falda Annie",
    description:
      "Hechas en gabardina rigida, faldas confeccionadas tipo libro con botones en ambos lados! Se hace a pedido y por talle.",
    price: 29100,
    description2:
      "Colores disponibles: Negro, verde militar, bordo, marron y mostaza.",
    price2: 0,
    category: ["faldas"],
    image: [
      "https://live.staticflickr.com/65535/53631752678_7e735564f0_c.jpg",
      "https://live.staticflickr.com/65535/53631752658_6f665cf355_c.jpg",
      "https://live.staticflickr.com/65535/53631988225_45356fa8df_c.jpg",
      "https://live.staticflickr.com/65535/53631988255_3dabb97519_c.jpg",
      "https://live.staticflickr.com/65535/53631543541_30286059b3_c.jpg",
      "https://live.staticflickr.com/65535/53630648792_7d5bfb404a_c.jpg",
    ],
    product_qty: 1,
  }, 
  {
    id: 36,
    name: "Enterito Seri Marrón",
    description:
      "Hechas en tela crep elastizado. Son sueltitos y frescos!",
    price: 55500,
    description2:
      "Pueden hacerse en más colores a pedido!",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/53644828991_7e76551550_c.jpg",
      "https://live.staticflickr.com/65535/53644829011_d2588c2bcd_c.jpg",
      "https://live.staticflickr.com/65535/53644829061_f18d4e66cd_c.jpg",
    ],
    product_qty: 1,
  }, 
  {
    id: 37,
    name: "Enterito Seri Celeste",
    description:
      "Hechas en tela crep elastizado. Son sueltitos y frescos!",
    price: 55500,
    description2:
      "Pueden hacerse en más colores a pedido!",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/53643951997_69df127a52_c.jpg",
      "https://live.staticflickr.com/65535/53643952012_de8f26f969_c.jpg",
      "https://live.staticflickr.com/65535/53644829001_d5496c253e_c.jpg",
      "https://live.staticflickr.com/65535/53645045583_1db3f5b467_c.jpg",
    ],
    product_qty: 1,
  }, 
  {
    id: 38,
    name: "Remeras Combinadas",
    description:
      "Hechas en tela jersey premium elastizada.",
    price: 22300,
    description2:
      "Colores disponibles: Beige, Marrón, Rosa, Rosa claro, Blanco y Negro.",
    price2: 0,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/53643954002_6375d199e0_c.jpg",
      "https://live.staticflickr.com/65535/53645169344_8002960ed5_c.jpg",
      "https://live.staticflickr.com/65535/53643953997_10a0acbfc9_c.jpg",
      "https://live.staticflickr.com/65535/53643953992_e05d54e246_c.jpg",
      "https://live.staticflickr.com/65535/53645289100_b63e69ff5e_c.jpg",
      "https://live.staticflickr.com/65535/53644830981_c258fe6b2c_c.jpg",
      "https://live.staticflickr.com/65535/53645289295_eac4387daa_c.jpg",
      "https://live.staticflickr.com/65535/53644831216_de65450bd7_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id:39,
    name: "Vestido Rosie",
    description:
    "Hecho en crep, con doble frunce frontal que llega hasta debajo del busto y otro frunce en el escote!",
    price: 44900,
    description2:
    "Puede hacerse en varios colores!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53960039162_e44c7c4f26_c.jpg",
      "https://live.staticflickr.com/65535/53960039167_989f17f381_c.jpg"
    ],
    product_qty: 1,
  },
  {
    id:40,
    name: "Vestido Noctis",
    description:
    "Vestido elastizado, hecho en tela transparente con forro. Con preciosas mangas anchas 🔥",
    price: 41700,
    description2:
    "Se hace a pedido en todos los talles y muchos más colores!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53960039527_9ae4550f2a_c.jpg",
      "https://live.staticflickr.com/65535/53960929066_1d1b0d85bc_c.jpg",
      "https://live.staticflickr.com/65535/53961253579_723171a047_c.jpg"
    ],
    product_qty: 1,
  },
  {
    id: 41,
    name: "Sweater Nubes Lila",
    description: "El sweater Nubes Lila está hecho en piel de cordero bifaz.",
    price: 52400,
    description2: "Súper suavecito, cómodo, calentito y hermoso!",
    price2: 0,
    category: ["abrigos"],
    image: [
      "https://live.staticflickr.com/65535/53961301699_d4211c3ef0_c.jpg",
      "https://live.staticflickr.com/65535/53960087217_a9dac2b96f_c.jpg",
      "https://live.staticflickr.com/65535/53961224853_9a7496df2a_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 42,
    name: "Sweater Lunas",
    description: "El sweater Lunas está hecho en piel de cordero bifaz.",
    price: 52400,
    description2: "Súper suavecito, cómodo, calentito y hermoso!",
    price2: 0,
    category: ["abrigos"],
    image: [
      "https://live.staticflickr.com/65535/53961224863_449150dfb8_c.jpg",
      "https://live.staticflickr.com/65535/53960975456_b35602b4ca_c.jpg",
      "https://live.staticflickr.com/65535/53961431555_68f88aaf98_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 43,
    name: "Sweater Honguitos",
    description: "El sweater Honguitos está hecho en piel de cordero bifaz.",
    price: 52400,
    description2: "Súper suavecito, cómodo, calentito y hermoso!",
    price2: 0,
    category: ["abrigos"],
    image: [
      "https://live.staticflickr.com/65535/53961224963_918053e970_c.jpg",
      "https://live.staticflickr.com/65535/53960087287_341b878e82_c.jpg",
      "https://live.staticflickr.com/65535/53960975451_1c68ce3772_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 44,
    name: "Vestido Corset",
    description: "Hecho en gabardina liviana rigida.",
    price: 46400,
    description2: "Puede usarse con el trenzado adelante y el cierre atras, o al revés.",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/53992392459_fd6d577347_c.jpg",
      "https://live.staticflickr.com/65535/53992311548_ebe3c1a118_c.jpg",
      "https://live.staticflickr.com/65535/53991189092_e4827da211_c.jpg",
      "https://live.staticflickr.com/65535/53992311553_5f8550c38d_c.jpg",
      "https://live.staticflickr.com/65535/53992078636_45c9d19afb_c.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 45,
    name: "Vestido Ofelia 🌸",
    description: "Hecho en fibrana.",
    price: 47500,
    description2: "Tiene un montón de detalles 🩷 Pinzas, frunce, volados, cinturón, doble falda, mangas con volados, es precioso 🌸",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/54011447949_68724d4e82_c.jpg",
      "https://live.staticflickr.com/65535/54010220547_0b088de30e_c.jpg",
      "https://live.staticflickr.com/65535/54011545370_ecaa90a982_c.jpg",
      "https://live.staticflickr.com/65535/54011447889_5cb265df08_c.jpg",
      "https://live.staticflickr.com/65535/54011120401_700a6b36ee_c.jpg",
      "https://live.staticflickr.com/65535/54011545350_c331d56cd4_c.jpg",
      "https://live.staticflickr.com/65535/54011545340_8437ebdc43_c.jpg"
    ],
    product_qty: 1,
  },
  {
    id: 46,
    name: "Vestido Dafne 💗🌸",
    description: "Diseño hermano del vestido Ofelia",
    price: 39900,
    description2: "Super delicado, con una falda campana que termina en volados. Tiene una cintura marcada y escote redondeado. Se hace por pedido en todos los talles y diferentes telas!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/54257954592_c8e32fc1a0_z.jpg",
      "https://live.staticflickr.com/65535/54259081269_6261d33373_z.jpg",
      "https://live.staticflickr.com/65535/54257954602_b939003469_z.jpg",
      "https://live.staticflickr.com/65535/54259081244_31d3fb7e40_z.jpg"
    ],
    product_qty: 1,
  },
  {
    id: 47,
    name: "Jardinero Jean Celeste 💫",
    description: "Hecho en Jean Celeste (La foto no le hace justicia al color)",
    price: 51800,
    description2: "Los botones pueden ser diferentes dependiendo el stock! Cómo siempre se hacen a pedido y en todos los talles.",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/54259265890_83dd4cc355.jpg",
      "https://live.staticflickr.com/65535/54259068353_2225d5758f.jpg",
      "https://live.staticflickr.com/65535/54258841181_f29074c400.jpg",
      "https://live.staticflickr.com/65535/54259068378_29013ffdff.jpg",
      "https://live.staticflickr.com/65535/54257953627_f9d5c20137.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 48,
    name: "Jardinero Negro 💫",
    description: "Hecho en Gabardina Negra",
    price: 47600,
    description2: "Los botones pueden ser diferentes dependiendo el stock! Cómo siempre se hacen a pedido y en todos los talles.",
    price2: 0,
    category: ["jardineros"],
    image: [
      "https://live.staticflickr.com/65535/54258841171_be4acd4afe.jpg",
      "https://live.staticflickr.com/65535/54259080229_4f0a3cac59.jpg",
      "https://live.staticflickr.com/65535/54259265795_30ca937040.jpg",
      "https://live.staticflickr.com/65535/54259068378_29013ffdff.jpg",
      "https://live.staticflickr.com/65535/54257953627_f9d5c20137.jpg",
    ],
    product_qty: 1,
  },
  {
    id: 49,
    name: "Vestido Corine 🖤",
    description: "Hecho en lycra acanalada, por lo que elastiza y se adapta super bien! Tiene terminaciones en puntillas.",
    price: 51200,
    description2: "Es muuuuy elastizado así que pidan el talle más bien justo! Puede hacerse en otros colores!",
    price2: 0,
    category: ["vestidos"],
    image: [
      "https://live.staticflickr.com/65535/54259268645_f4a411048e.jpg",
      "https://live.staticflickr.com/65535/54257956342_2c48b5e819.jpg",
      "https://live.staticflickr.com/65535/54258843961_2f93780cc5.jpg",
      "https://live.staticflickr.com/65535/54258843971_9907ab8eba.jpg",
    ],
    product_qty: 1,
  },
  
  {
    id: 50,
    name: "Top Kitty 💞",
    description: "Hecho en microfibra, la tela es rígida.",
    price: 25900,
    description2: "Se puede pedir en otras estampas y colores lisos 🌈",
    price2: 0,
    category: ["tops"],
    image: [
      "https://live.staticflickr.com/65535/54373302316_98fcd69fc1.jpg",
      "https://live.staticflickr.com/65535/54372425182_fd3e640c25.jpg",
      "https://live.staticflickr.com/65535/54372441482_01e24755e4.jpg",
      "https://live.staticflickr.com/65535/54373542988_81428e3bbe.jpg",
      "https://live.staticflickr.com/65535/54373695100_b120bb9eb1.jpg",
      "https://live.staticflickr.com/65535/54373542998_6e3419d44f.jpg",
      "https://live.staticflickr.com/65535/54373542983_07dd77fd85.jpg",
    ],
    product_qty: 1,
  },
];

const categories = [
  "tops",
  "faldas",
  "vestidos",
  "abrigos",
  "camisas",
  "jardineros",
];

module.exports = {
  products,
  categories,
};
